import { LinearProgress, Typography } from '@mui/material';
import { useState } from 'react';
import useWebsocket from '../../hooks/useWebsocket';
import { ComparisonStatus } from '../../enums/ComparisonStatus';

interface Props {
    comparisonId: string;
    tenantId: string;
    onComparisonReady?: (message) => void;
    onMessage?: (topic: string, message: any) => void;
}

export default function ComparisonProcessingProgress(props: Props) {
    const [progress, setProgress] = useState(0);
    const [progressMsg, setProgressMsg] = useState('');

    if (!props.comparisonId) throw new Error('comparisonId is required');

    const clientId = `comparison_${props.comparisonId}_listener`;
    const topic = `${props?.tenantId}/clause-comparison/${props.comparisonId}/updates`;

    const { wsClient } = useWebsocket(
        { clientId, topics: [topic] },
        {
            onMessage: (topic, message) => messageHandler(topic, message),
        }
    );

    const messageHandler = (topic: string, message: any) => {
        if (message?.progress) setProgress(message.progress);
        if (message?.message) setProgressMsg(message.message);

        if (message?.status === ComparisonStatus.READY) {
            props?.onComparisonReady && props.onComparisonReady(message);
            wsClient?.end();
        }

        props?.onMessage && props.onMessage(topic, message);
    };

    return (
        <>
            <LinearProgress variant="determinate" value={0} />
            <Typography variant="body2" fontSize={12} color="text.secondary">
                {progress}% - {progressMsg ?? '...'}
            </Typography>
        </>
    );
}
