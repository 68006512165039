import { IconButton, TableCell, TableRow } from '@mui/material';
import { ISavedQuery } from '../../../@types/SavedQuery';
import SavedQueryLink from '../../UI/SavedQueryLink';
import Container from '../../UI/Common/Container';
import { DeleteOutline } from '@mui/icons-material';
import DeleteSavedQueryDialog from './DeleteSavedQueryDialog';
import { useState } from 'react';

interface Props {
    savedQuery: ISavedQuery;
    onDeleteSuccess: () => void;
}

export default function SavedQueriesTableItem({ savedQuery, onDeleteSuccess }: Props) {
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    return (
        <>
            <TableRow
                key={savedQuery.query}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
                <TableCell>
                    <Container maxWidth={17} wordBreak>
                        <SavedQueryLink savedQuery={savedQuery} />
                    </Container>
                </TableCell>
                <TableCell>{savedQuery.query_description}</TableCell>
                <TableCell>
                    <IconButton color="inherit" onClick={() => setShowDeleteDialog(true)}>
                        <DeleteOutline />
                    </IconButton>
                </TableCell>
            </TableRow>
            <DeleteSavedQueryDialog
                open={showDeleteDialog}
                savedQuery={savedQuery}
                onClose={() => {
                    setShowDeleteDialog(false);
                }}
                onDeleteSuccess={() => {
                    setShowDeleteDialog(false);
                    onDeleteSuccess();
                }}
            />
        </>
    );
}
