export enum DateFilterOperator {
    IS_BEFORE = 'is before',
    IS_BEFORE_EQUAL = 'is before or equal',
    IS_AFTER = 'is after',
    IS_AFTER_EQUAL = 'is after or equal',
    IS_EQUAL = 'is equal',
    IS_BETWEEN = 'is between',
}

export function mapDisplayOperator(operator) {
    let str = '';

    switch (operator) {
        case 'lt':
            str = DateFilterOperator.IS_BEFORE;
            break;
        case 'lte':
            str = DateFilterOperator.IS_BEFORE_EQUAL;
            break;
        case 'gt':
            str = DateFilterOperator.IS_AFTER;
            break;
        case 'gte':
            str = DateFilterOperator.IS_AFTER_EQUAL;
            break;
        default:
            str = DateFilterOperator.IS_EQUAL;
    }

    return str;
}

export const formDatePayload = (selectorValue, date: string | string[]) => {
    let datePayload = {};
    switch (selectorValue) {
        case DateFilterOperator.IS_BEFORE:
            datePayload = {
                lt: date,
            };
            break;
        case DateFilterOperator.IS_BEFORE_EQUAL:
            datePayload = {
                lte: date,
            };
            break;
        case DateFilterOperator.IS_AFTER:
            datePayload = {
                gt: date,
            };
            break;
        case DateFilterOperator.IS_AFTER_EQUAL:
            datePayload = {
                gte: date,
            };
            break;
        case DateFilterOperator.IS_EQUAL:
            datePayload = {
                gte: date,
                lte: date,
            };
            break;
        case DateFilterOperator.IS_BETWEEN:
            datePayload = {
                gte: date[0],
                lte: date[1],
            };
            break;
        default:
            datePayload = {
                lt: date,
            };
    }

    return datePayload;
};
