import styled from 'styled-components';

const StyledMessage = styled.p`
    border-radius: 1.15rem;
    line-height: 1.25;
    max-width: 75%;
    padding: 0.6rem 0.975rem;
    position: relative;
    word-wrap: break-word;
    font-size: 14px;

    &::before,
    &::after {
        bottom: -0.1rem;
        content: '';
        height: 1rem;
        position: absolute;
    }

    &.from-me {
        align-self: flex-end;
        background-color: #eceaeb;
        color: #0e0e0e;
    }

    &.from-me::before {
        border-bottom-left-radius: 0.8rem 0.7rem;
        border-right: 1rem solid #eceaeb;
        right: -0.35rem;
        transform: translate(0, -0.1rem);
    }

    &.from-me::after {
        background-color: #fff;
        border-bottom-left-radius: 0.5rem;
        right: -40px;
        transform: translate(-30px, -2px);
        width: 10px;
    }

    &[class^='from-'] {
        margin: 0.5rem 0;
        width: fit-content;
    }

    &.from-me ~ p.from-me {
        margin: 0.25rem 0 0;
    }

    &.from-me ~ p.from-me:not(:last-child) {
        margin: 0.25rem 0 0;
    }

    &.from-me ~ p.from-me:last-child {
        margin-bottom: 0.5rem;
    }

    &.from-them {
        align-items: flex-start;
        background-color: #e7f1fe;
        color: #000;
    }

    &.from-them:before {
        border-bottom-right-radius: 0.8rem 0.7rem;
        border-left: 1rem solid #e7f1fe;
        left: -0.35rem;
        transform: translate(0, -0.1rem);
    }

    &.from-them::after {
        background-color: #fff;
        border-bottom-right-radius: 0.5rem;
        left: 20px;
        transform: translate(-30px, -2px);
        width: 10px;
    }
`;

interface ChatBubbleProps {
    children: React.ReactNode;
    isFromMe: boolean;
}

const ChatBubble = ({ children, isFromMe }: ChatBubbleProps) => {
    return <StyledMessage className={isFromMe ? 'from-me' : 'from-them'}>{children}</StyledMessage>;
};

export default ChatBubble;
