import Flex from 'styled-flex-component';
import { Text } from '../../UI/Typography';
import Container from '../../UI/Common/Container';
import { Theme } from '../../../theme';
import { DragIndicator, Visibility, VisibilityOff, Warning } from '@mui/icons-material';
import { Chip, IconButton, Tooltip } from '@mui/material';
import { ISelectedEntity } from '../helpers/types';
import { useState } from 'react';

interface ICLausesListItemProps {
    index: number;
    clause: {
        id: string;
        key: string;
    };
    hoveredInsight: string;
    draggedInsight: string;
    setHoveredInsight: (string) => void;
    isEditing: (string) => boolean;
    removeInsight: (e: any, clause: any, index: any) => void;
    displayInsight: (clause: any, index: any) => void;
    setSelectedInsight: (insight: string | ISelectedEntity | undefined) => void;
    setTextMatchIndex: (index: number) => void;
    isHideable: boolean;
    isParsed: boolean;
    editSelection: (e: any, clause: any) => void;
}

const ClausesListItem = ({
    index,
    clause,
    hoveredInsight,
    draggedInsight,
    setHoveredInsight,
    isEditing,
    removeInsight,
    displayInsight,
    setSelectedInsight,
    setTextMatchIndex,
    isHideable,
    isParsed,
    editSelection,
}: ICLausesListItemProps) => {
    const [open, setOpen] = useState(false);
    const handleClose = () => {
        setOpen(false);
    };

    const handleOpen = () => {
        setOpen(true);
    };

    return (
        <Container
            onClick={() => {
                setTextMatchIndex(0);
                setSelectedInsight(isHideable ? clause.key : '');
            }}
            pointerEvents={isEditing(clause.key) && isHideable ? 'none' : ''}
            backgroundColor={
                isEditing(clause.key) && isHideable
                    ? 'rgba(13, 24, 50, 0.3)'
                    : hoveredInsight == clause.key || draggedInsight == clause.id
                    ? 'whitesmoke'
                    : 'white'
            }
            display="flex"
            data-testid="clause-row"
            cursor="pointer"
            borderBottom="1px solid rgba(13, 24, 50, 0.12)"
            rightInnerSpacing={1}
            height={5}
            onMouseEnter={() => {
                setHoveredInsight(clause.key);
            }}
            onMouseLeave={() => {
                setHoveredInsight('');
            }}
        >
            <Flex alignCenter justifyCenter>
                <IconButton
                    color="default"
                    style={{
                        visibility:
                            hoveredInsight == clause.key || draggedInsight == clause.id
                                ? 'visible'
                                : 'hidden',
                        height: '40px',
                        backgroundColor: 'transparent',
                    }}
                >
                    <DragIndicator />
                </IconButton>
            </Flex>
            <Flex justifyBetween alignCenter style={{ width: '100%' }}>
                <Flex alignCenter>
                    {!isParsed && (
                        <Tooltip
                            PopperProps={{ style: { pointerEvents: 'none' } }}
                            open={open}
                            onClose={handleClose}
                            onOpen={handleOpen}
                            title="Unable to extract the corresponding insight."
                            style={{ background: 'yellow' }}
                        >
                            <IconButton
                                style={{
                                    backgroundColor: 'transparent',
                                    padding: 0,
                                    borderRadius: 0,
                                    marginRight: '5px',
                                }}
                                onClick={(e) => {
                                    setSelectedInsight(isHideable ? clause.key : '');
                                    editSelection(e, clause);
                                }}
                            >
                                <Warning
                                    sx={{ color: Theme.warning }}
                                    style={{ marginRight: '5px', cursor: 'default' }}
                                />
                                {open && (
                                    <Chip
                                        label="Edit"
                                        style={{ height: '25px', cursor: 'pointer' }}
                                        color="warning"
                                        variant="outlined"
                                    />
                                )}
                            </IconButton>
                        </Tooltip>
                    )}
                    <Text
                        color={
                            isHideable
                                ? Theme.primaryDark
                                : hoveredInsight == clause.key || draggedInsight == clause.id
                                ? Theme.primaryDark
                                : 'darkgray'
                        }
                        dangerouslySetInnerHTML={{ __html: clause.key }}
                    />
                </Flex>
            </Flex>
            <Flex alignCenter justifyCenter>
                <IconButton
                    color="default"
                    style={{
                        visibility: hoveredInsight == clause.key ? 'visible' : 'hidden',
                        height: '40px',
                    }}
                    onClick={(e) => {
                        isHideable
                            ? removeInsight(e, clause, index)
                            : displayInsight(clause, index);
                    }}
                >
                    {isHideable ? (
                        <VisibilityOff sx={{ color: 'rgba(13, 24, 50, 0.54)' }} />
                    ) : (
                        <Visibility sx={{ color: 'rgba(13, 24, 50, 0.54)' }} />
                    )}
                </IconButton>
            </Flex>
        </Container>
    );
};

export default ClausesListItem;
