import { IWorkflow } from '../../../@types/Workflow';
import { Theme } from '../../../theme';
import Container from '../../UI/Common/Container';
import { Text } from '../../UI/Typography';

interface IWorkflowLinkItemProps {
    workflow: IWorkflow;
}

const WorkflowLink = ({ workflow }: IWorkflowLinkItemProps) => {
    return (
        <Container
            cursor="pointer"
            onClick={() => window.open(`https://n8n.melior.ai/workflow/${workflow.id}`, '_blank')}
        >
            <Text customFontWeight={500} color={Theme.primary}>
                <p dangerouslySetInnerHTML={{ __html: workflow.name }} />
            </Text>
        </Container>
    );
};

export default WorkflowLink;
