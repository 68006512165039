import { TableCell, TableRow } from '@mui/material';
import { IClause } from '../../../@types/SavedQuery';
import { StyledClauseTextField } from '../../../components/ClauseLibrary/StyledClauseTextField';
import { useState } from 'react';
import { updateClause } from '../../../api/clauseLibrary.api';
import { toast } from 'react-toastify';

interface Props {
    clause: IClause;
    onDeleteSuccess: () => void;
}

export default function ClauseLibraryTableItem({ clause }: Props) {
    const [definition, setDefinition] = useState<string>(clause.definition);

    const saveChanges = (value) => {
        if (clause.definition != value) {
            updateClause([{ name: clause.name, definition: value }]).then((res) => {
                if (res.total_updated) {
                    clause.definition = value;
                    setDefinition(value);
                    toast.success(`Clause definition saved successfully`);
                } else {
                    toast.success(`Error saving clause definition.`);
                }
            });
        }
    };

    return (
        <>
            <TableRow key={clause.name} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell style={{ width: '25%' }}>{clause.name}</TableCell>
                <TableCell style={{ width: '75%' }}>
                    <StyledClauseTextField
                        fullWidth
                        multiline
                        sx={{ background: 'rgba(0, 0, 0, 0.06)' }}
                        placeholder=""
                        value={definition}
                        onBlur={(e) => saveChanges(e.target.value)}
                        onChange={(e) => setDefinition(e.target.value)}
                    />
                </TableCell>
            </TableRow>
        </>
    );
}
