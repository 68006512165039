import { IDocument } from '../../../@types/Document';
import { Theme } from '../../../theme';
import { NavLink } from 'react-router-dom';

interface IDashboardDocumentItemProps {
    document: IDocument;
}

const DocumentLink = ({ document }: IDashboardDocumentItemProps) => {
    return (
        <NavLink
            style={{ cursor: 'pointer', color: Theme.primary, textDecoration: 'none' }}
            to={`/client/insights/${document.id}`}
        >
            {document.name}
        </NavLink>
    );
};

export default DocumentLink;
