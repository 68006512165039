import Select from '@mui/material/Select';
import styled from 'styled-components';
import { Theme } from '../../../theme';

export const StyledFilterTypeSelector = styled(Select)`
    min-width: 150px;
    background: ${Theme.highlight};
    border-radius: 150px !important;

    fieldset {
        border: none;
    }
`;
