import { Box, Link, TableCell, TableRow } from '@mui/material';
import Container from '../UI/Common/Container';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { IClauseResult } from '../../@types/Comparison';
import { capitalize } from 'lodash';

interface Props {
    result: IClauseResult;
    docId: string;
    isHighlighted: boolean;
}

export default function ComparisonResultItem(props: Props) {
    const { result, docId, isHighlighted } = props;
    const [entailment, setEntailment] = useState<any>();
    const navigate = useNavigate();

    useEffect(() => {
        let index,
            maxIndex = 0;
        result.entailment.forEach((entObj: any) => {
            if (index == 0 || entObj[0] > entObj[maxIndex][0]) maxIndex = index;
            index++;
        });
        setEntailment(result.entailment[maxIndex]);
    }, []);
    return (
        <>
            <TableRow
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                key={result.clause}
            >
                {/* Clause Name */}
                <TableCell>
                    <Container wordBreak>{result.clause}</Container>
                </TableCell>
                {/* Approved Clause */}
                <TableCell>
                    <Box
                        sx={{
                            display: 'flex',
                            flexWrap: 'wrap',
                            overflow: 'auto',
                        }}
                    >
                        {result.diff.map(
                            (word, index) =>
                                word.charAt(0) != '+' &&
                                word.charAt(0) != '?' && (
                                    <div
                                        key={index}
                                        style={{
                                            height: '16px',
                                            background: `${
                                                isHighlighted
                                                    ? word.charAt(0) == '-'
                                                        ? 'rgba(255, 204, 203)'
                                                        : ''
                                                    : ''
                                            }`,
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            display: 'flex',
                                            marginTop: '3px',
                                        }}
                                    >
                                        <p style={{ marginRight: '5px' }}>
                                            {word.charAt(0) == '-'
                                                ? word.substring(2, word.length)
                                                : word}
                                        </p>
                                    </div>
                                )
                        )}
                    </Box>
                </TableCell>
                {/* Extracted Clause */}
                <TableCell>
                    <Box
                        sx={{
                            display: 'flex',
                            flexWrap: 'wrap',
                            overflow: 'auto',
                        }}
                    >
                        {result.diff.map(
                            (line) =>
                                line.charAt(0) != '-' &&
                                line.charAt(0) != '?' &&
                                line
                                    .substring(2, line.length)
                                    .split(' ')
                                    .map((word, index) => (
                                        <div
                                            key={index}
                                            style={{
                                                height: '16px',
                                                background: `${
                                                    isHighlighted
                                                        ? line.charAt(0) == '+'
                                                            ? 'rgba(16, 185, 129, 0.3)'
                                                            : ''
                                                        : ''
                                                }`,
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                display: 'flex',
                                                marginTop: '3px',
                                            }}
                                        >
                                            <p style={{ marginRight: '5px' }}>
                                                {word != '+' ? word : ''}
                                            </p>
                                        </div>
                                    ))
                        )}
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            width: 'full',
                            justifyContent: 'center',
                            marginTop: '10px',
                        }}
                    >
                        <Link
                            onClick={() =>
                                navigate(`/client/insights/${docId}?clause=${result.clause}`)
                            }
                        >
                            See in Document
                        </Link>
                    </Box>
                </TableCell>
                {/* Semantic Comparison */}
                <TableCell>
                    <Container wordBreak>{Math.trunc(result.paraphrasing * 100)}%</Container>
                </TableCell>
                {/* Entailment */}
                <TableCell>
                    {entailment && (
                        <Container wordBreak>
                            <p
                                style={{
                                    color: `${
                                        entailment[1] == 'neutral'
                                            ? 'rgba(0, 144, 188)'
                                            : entailment[1] == 'contradiction'
                                            ? 'rgba(220, 20, 60)'
                                            : ''
                                    }`,
                                    fontWeight: 'bold',
                                }}
                            >
                                {capitalize(entailment[1])}
                            </p>
                        </Container>
                    )}
                </TableCell>
            </TableRow>
        </>
    );
}
