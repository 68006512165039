import styled, { css } from 'styled-components';

export const StyledUnorderedList = styled('ul')`
    list-style-type: none;
    padding-left: 0;
    margin-top: 0;
    margin-bottom: 0;
`;

interface StyledListItemProps {
    isActive: boolean;
    isDisabled: boolean;
}

export const StyledListItem = styled<StyledListItemProps>('li')`
    ${({ isActive }) => css`
        ${isActive &&
        css`
            background: rgba(255, 255, 255, 0.08);
            border-radius: 4px;
        `}
    `}

    .linkContainer {
        padding-top: 0.75rem;
        padding-bottom: 0.75rem;
        padding-left: 1.1875rem;
        padding-right: 1.1875rem;
    }

    a {
        color: rgba(255, 255, 255, 0.54);
        text-decoration: none;

        ${({ isDisabled }) => css`
            ${isDisabled &&
            css`
                pointer-events: none;
                color: rgba(255, 255, 255, 0.2);
            `}
        `}

        ${({ isActive }) => css`
            ${isActive &&
            css`
                color: white;
            `}
        `}
    }

    &:hover {
        background: rgba(255, 255, 255, 0.05);
        border-radius: 4px;

        a {
            color: white;
            ${({ isDisabled }) => css`
                ${isDisabled &&
                css`
                    color: rgba(255, 255, 255, 0.2);
                `}
            `}
        }

        ${({ isDisabled }) => css`
            ${isDisabled &&
            css`
                background: transparent;
            `}
        `}
    }
`;
