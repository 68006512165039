import { Box, LinearProgress, Typography } from '@mui/material';
import useWebsocket from '../../../hooks/useWebsocket';
import { getUserSessionData } from '../../../utils/userSession';
import { useState } from 'react';
import { DocumentStatus } from '../../../enums/DocumentStatus';

interface Props {
    sha1: string;
    onDocumentReady?: (message) => void;
}

export default function WSPageLoadingPlaceholder(props: Props) {
    const user = getUserSessionData();
    const [message, setMessage] = useState<string>('');
    const [progress, setProgress] = useState<number>(0);

    const messageHandler = (_topic: string, message: any) => {
        setMessage(message.message);
        setProgress(message.progress);

        if (message?.status === DocumentStatus.READY) {
            props?.onDocumentReady && props.onDocumentReady(message);
        }
    };

    useWebsocket(
        {
            clientId: `doc_sha1_listener`,
            topics: [`${user?.tenantId}/document/${props.sha1}/updates`],
        },
        {
            onMessage: (topic, message) => messageHandler(topic, message),
        }
    );

    return (
        <div
            style={{
                alignContent: 'center',
                display: 'flex',
                justifyContent: 'center',
                height: '100vh',
            }}
        >
            <Box
                style={{
                    width: '50%',
                    padding: '2em',
                    boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.2)',
                    borderRadius: '10px',
                    margin: 'auto',
                }}
            >
                <Typography
                    variant="h5"
                    align="center"
                    style={{
                        textAlign: 'left',
                        marginBottom: '.3em',
                    }}
                >
                    {message || 'Processing...'}
                </Typography>
                <LinearProgress variant="buffer" value={progress} valueBuffer={0} />
                <Typography variant="body2" color="text.secondary">
                    {progress}%
                </Typography>
            </Box>
        </div>
    );
}
