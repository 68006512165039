import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { TableContainer } from '@mui/material';
import ComparisonsTableItem from './Item';
import { Text } from '../../UI/Typography';
import { IComparison } from '../../../@types/Comparison';
import { MeliorTranslate } from '../../MeliorTranslate';

interface IDocumentsTableProps {
    comparisons: IComparison[];
    refetch: () => void;
}

const ComparisonsTable = ({ comparisons, refetch }: IDocumentsTableProps) => {
    return (
        <TableContainer>
            <Table id="comparisonTable">
                <TableHead>
                    <TableRow>
                        <TableCell style={{ width: '25%' }}>
                            <Text customFontWeight={700}>
                                <MeliorTranslate valueKey="Name" />
                            </Text>
                        </TableCell>
                        <TableCell style={{ width: '45%' }}>
                            <Text customFontWeight={700}>
                                <MeliorTranslate valueKey="Description" />
                            </Text>
                        </TableCell>
                        <TableCell style={{ width: '10%' }}>
                            <Text customFontWeight={700}>
                                <MeliorTranslate valueKey="Status" />
                            </Text>
                        </TableCell>
                        <TableCell style={{ width: '20%' }}>&nbsp;</TableCell>
                        <TableCell style={{ width: '5%' }}>&nbsp;</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {comparisons.map((comparison: IComparison) => (
                        <ComparisonsTableItem
                            key={comparison.id}
                            comparison={comparison}
                            onDeleteSuccess={refetch}
                            onReUploadSuccess={refetch}
                        />
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default ComparisonsTable;
