import { Input } from '@mui/material';
import Container from '../../UI/Common/Container';
import { IAdvancedSearchPageSearchFilter } from '../../../pages/Client/AdvancedSearch/Page';

interface ISearchTextFilterFieldProps {
    clause: IAdvancedSearchPageSearchFilter;
    filtersAdded: IAdvancedSearchPageSearchFilter[];
    setFiltersAdded: (clauses: IAdvancedSearchPageSearchFilter[]) => void;
}

const SearchTextFilterField = ({
    clause,
    filtersAdded,
    setFiltersAdded,
}: ISearchTextFilterFieldProps) => {
    const handleChange = (text) => {
        const newArr: IAdvancedSearchPageSearchFilter[] = filtersAdded.map((filter) => {
            if (filter.filterLabel === clause.filterLabel) {
                filter.value = text;
                filter.display = `has ${text}`;
            }
            return filter;
        }) as unknown as IAdvancedSearchPageSearchFilter[];
        setFiltersAdded(newArr);
    };

    return (
        <Container leftOuterSpacing={1} rightOuterSpacing={1}>
            <Input
                autoComplete="off"
                value={clause.value}
                onChange={(e) => handleChange(e.target.value)}
                fullWidth
            />
        </Container>
    );
};

export default SearchTextFilterField;
