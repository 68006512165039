import { lighten, useTheme } from '@mui/material';
import { CSSProperties } from 'react';

export default function useAnnouncementBannerStyles(): {
    [key: string]: CSSProperties;
} {
    const theme = useTheme();
    const commonStyles = {
        padding: '0.8em',
        paddingBottom: 0,
        marginBottom: '1em',
        overflow: 'hidden',
    };

    return {
        info: {
            ...commonStyles,
            border: '1px solid ' + theme.palette.info.main,
            backgroundColor: lighten(theme.palette.info.light, 0.9),
        },
        success: {
            ...commonStyles,
            border: '1px solid ' + theme.palette.success.main,
            backgroundColor: lighten(theme.palette.success.light, 0.9),
        },
        warning: {
            ...commonStyles,
            border: '1px solid ' + theme.palette.warning.main,
            backgroundColor: lighten(theme.palette.warning.light, 0.9),
        },
        error: {
            ...commonStyles,
            border: '1px solid ' + theme.palette.error.main,
            backgroundColor: lighten(theme.palette.error.light, 0.9),
        },
    };
}
