import { ISavedQuery } from '../../../@types/SavedQuery';
import { Theme } from '../../../theme';
import Container from '../../UI/Common/Container';
import { Text } from '../../UI/Typography';

import { useNavigate } from 'react-router-dom';

interface ISavedQUeryItemProps {
    savedQuery: ISavedQuery;
}

const SavedQueryLink = ({ savedQuery }: ISavedQUeryItemProps) => {
    const navigate = useNavigate();

    const searchParams = () => {
        let queryStr = '';

        if (savedQuery.query) {
            queryStr += `query=${savedQuery.query.replaceAll('"', '')}`;
        }

        const filters = Object.keys(savedQuery.filters);

        if (!filters.length) {
            return queryStr;
        }

        queryStr += `&filters=`;
        queryStr += filters.map((filter) => {
            const cleanFilter = filter.replaceAll('clauses.', '').replaceAll('.text', '');

            if (filter === 'type') {
                const typesArr: string[] = savedQuery.filters[filter] as unknown as string[];
                return typesArr.map((type) => {
                    return `Type=${type}`;
                });
            }

            if (savedQuery.filters[filter].exists) {
                return cleanFilter;
            }

            if (filter.includes('Date')) {
                const operator = Object.keys(savedQuery.filters[filter])[0];
                const value = savedQuery.filters[filter][operator];
                return `${cleanFilter}=${operator}.${value}`;
            }

            return `${cleanFilter}=${savedQuery.filters[filter]}`;
        });

        return queryStr;
    };
    return (
        <Container
            cursor="pointer"
            onClick={() => navigate(`/client/advanced-search?${searchParams()}`)}
        >
            <Text customFontWeight={500} color={Theme.primary}>
                {savedQuery.query_name}
            </Text>
        </Container>
    );
};

export default SavedQueryLink;
