import { useEffect, useState } from 'react';
import { getUserSessionData } from '../../utils/userSession';

export default function useDismissedAnnouncements() {
    const [dismissedAnnouncements, setDismissedAnnouncements] = useState<string[]>([]);
    const user = getUserSessionData();

    const dismissAnnouncementsKey = `${user?.sub}_MeliorDismissedAnnouncements`;

    useEffect(() => {
        const dismissedAnnouncements = localStorage.getItem(dismissAnnouncementsKey);

        if (dismissedAnnouncements) {
            setDismissedAnnouncements(JSON.parse(dismissedAnnouncements));
        }
    }, []);

    const dismissAnnouncement = (id: string) => {
        const newDismissedAnnouncements = [...dismissedAnnouncements, id];
        localStorage.setItem(dismissAnnouncementsKey, JSON.stringify(newDismissedAnnouncements));
        setDismissedAnnouncements(newDismissedAnnouncements);
    };

    const resetDismissedAnnouncements = () => {
        localStorage.removeItem(dismissAnnouncementsKey);
        setDismissedAnnouncements([]);
    };

    const isDismissed = (id: string) => dismissedAnnouncements.includes(id);

    return {
        dismissedAnnouncements,
        dismissAnnouncement,
        resetDismissedAnnouncements,
        isDismissed,
    };
}
