import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { IClause } from '../../../@types/SavedQuery';
import ClauseLibraryTableItem from './Item';
import { Text } from '../../UI/Typography';
import { MeliorTranslate } from '../../MeliorTranslate';

interface IClauseLibraryTableProps {
    clauseLibrary: IClause[];
    refetch: () => void;
}

const ClauseLibraryTable = ({ clauseLibrary, refetch }: IClauseLibraryTableProps) => {
    const rows: IClause[] = clauseLibrary.map((item: IClause) => ({
        name: item.name,
        definition: item.definition,
    })) as IClause[];
    return (
        <TableContainer>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>
                            <Text customFontWeight={700}>
                                <MeliorTranslate valueKey="Title" />
                            </Text>
                        </TableCell>
                        <TableCell>
                            <Text customFontWeight={700}>
                                <MeliorTranslate valueKey="Definition" />
                            </Text>
                        </TableCell>
                        <TableCell>&nbsp;</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.map((clause: IClause) => (
                        <ClauseLibraryTableItem
                            key={clause.name}
                            clause={clause}
                            onDeleteSuccess={refetch}
                        />
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default ClauseLibraryTable;
