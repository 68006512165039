import { User } from '@frontegg/redux-store/auth/interfaces';

const USER_DATA_KEY = 'frontEggUser';

export function saveUserSessionData(user: User) {
    localStorage.setItem(USER_DATA_KEY, JSON.stringify(user));
    return user;
}

export function getUserSessionData(): User | null {
    const user = localStorage.getItem(USER_DATA_KEY);
    return user ? JSON.parse(user) : null;
}

export function updateUserSessionData(key: string, value: any) {
    const user = getUserSessionData();
    if (user) {
        user[key] = value;
        saveUserSessionData(user);
    }
}

export function removeUserSessionData() {
    localStorage.removeItem(USER_DATA_KEY);
}
