import { useQuery } from '@tanstack/react-query';
import { getWorkflows } from '../api/n8n/workflows';
import { IN8NWorkflowResponse } from '../@types/Workflow';

export function useWorkflows(params, queryKey) {
    const { data, ...rest } = useQuery<IN8NWorkflowResponse>({
        queryFn: () => getWorkflows(params),
        queryKey: [queryKey],
        refetchOnWindowFocus: false,
    });

    return {
        workflows: data?.data ?? ([] as any),
        nextCursor: data?.nextCursor,
        ...rest,
    };
}
