import { ITemplate } from '../@types/Template';
import apiClient from './apiClient';

export const getTemplates = async () => {
    return apiClient.get('/template/').then((res) => res.data);
};

export const createTemplate = async (params: ITemplate) => {
    return apiClient.put('/template/', params).then((res) => res.data);
};

export const deleteTemplate = async (documentType: string) => {
    return apiClient.delete(`/template/${documentType}/delete`).then((res) => res.data);
};
