import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

interface DomainUnreachableDialogProps {
    unreachableDomain: string;
}

const DomainUnreachableDialog = ({ unreachableDomain }: DomainUnreachableDialogProps) => {
    function refresh() {
        location.reload();
    }
    return (
        <Dialog
            open={Boolean(unreachableDomain)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle color="red">Warning</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Domain <strong>{unreachableDomain}</strong> is not accessible and perhaps ask if
                    a firewall or similar might be blocking it.
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={refresh}>Refresh</Button>
            </DialogActions>
        </Dialog>
    );
};

export default DomainUnreachableDialog;
