import styled, { css } from 'styled-components';

interface INavBarStyled {
    isSideBarMaximized: boolean;
}

export default styled<INavBarStyled>('header')`
    position: fixed;
    padding-top: 0.9375rem;
    padding-bottom: 0.9375rem;
    background: #ffffff;
    box-shadow: 0px 2px 4px rgba(16, 35, 77, 0.06);

    ${({ isSideBarMaximized }: INavBarStyled) =>
        css`
            ${isSideBarMaximized ? `width: calc(100vw - 248px)` : `width: calc(100vw - 80px)`};
            ${isSideBarMaximized ? `margin-left: 248px;` : `margin-left: 80px;`};
        `};
`;
