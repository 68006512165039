export interface IN8NWorkflowResponse {
    data: IWorkflow[];
    nextCursor: string;
}

export interface IWorkflow {
    active: boolean;
    name: string;
    id: string;
    nodes: IWorkflowNode[];
    tags: {
        id: string;
        name: string;
    }[];
}

export interface IWorkflowNode {
    type: EnumWorkflowNodeType;
    webhookId: string;
}

export enum EnumWorkflowNodeType {
    WEBHOOK = 'n8n-nodes-base.webhook',
}
