import { createContext, useState } from 'react';
import { IAdvancedSearchPageSearchFilter } from '../Page';

export type AdvancedSearchContextType = {
    searchText: string;
    filtersAdded: IAdvancedSearchPageSearchFilter[];
    matchingClauses: string[];
    agreementType: string;
    selectedClauseIndex: number | null;
    setSelectedClauseIndex: (val: number | null) => void;
};

export const AdvancedSearchContext = createContext<AdvancedSearchContextType | null>(null);

interface AdvancedSearchProviderProps {
    children: React.ReactNode;
    searchText: string;
    filtersAdded: IAdvancedSearchPageSearchFilter[];
    matchingClauses: string[];
    agreementType: string;
}

const AdvancedSearchProvider = ({
    children,
    searchText,
    filtersAdded,
    matchingClauses,
    agreementType,
}: AdvancedSearchProviderProps) => {
    const [selectedClauseIndex, setSelectedClauseIndex] = useState<number | null>(null);
    return (
        <AdvancedSearchContext.Provider
            value={{
                searchText,
                filtersAdded,
                matchingClauses,
                agreementType,
                selectedClauseIndex,
                setSelectedClauseIndex,
            }}
        >
            {children}
        </AdvancedSearchContext.Provider>
    );
};

export default AdvancedSearchProvider;
