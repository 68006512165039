import { IAdvancedSearchPageSearchFilter } from '../../../pages/Client/AdvancedSearch/Page';
import Container from '../../UI/Common/Container';
import DocumentTypeSelector from '../../UI/DocumentTypeSelector.tsx';

interface ISearchAgreementTypeFilterProps {
    clause: IAdvancedSearchPageSearchFilter;
    filtersAdded: IAdvancedSearchPageSearchFilter[];
    selectedClauseIndex: number | null;
    setFiltersAdded: (clauses: IAdvancedSearchPageSearchFilter[]) => void;
}

const SearchAgreementTypeFilterField = ({
    clause,
    filtersAdded,
    selectedClauseIndex,
    setFiltersAdded,
}: ISearchAgreementTypeFilterProps) => {
    return (
        <Container leftOuterSpacing={1} rightOuterSpacing={1}>
            <DocumentTypeSelector
                selectedType={clause.value ? (clause.value as string) : ''}
                setSelectedType={(agreementType: string) => {
                    const newArr: IAdvancedSearchPageSearchFilter[] = filtersAdded.map(
                        (filter, index) => {
                            if (index === selectedClauseIndex) {
                                filter.value = agreementType;
                                filter.display = `is ${agreementType}`;
                            }
                            return filter;
                        }
                    ) as unknown as IAdvancedSearchPageSearchFilter[];
                    setFiltersAdded(newArr);
                }}
            />
        </Container>
    );
};

export default SearchAgreementTypeFilterField;
