import styled from 'styled-components';

export const StyledDropdownResults = styled.div`
    position: absolute;
    width: 100%;
    background: white;
    top: 50px;
    z-index: 10;
    box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px rgba(0, 0, 0, 0.14),
        0px 1px 18px rgba(0, 0, 0, 0.12);
`;
