import React from 'react';
import { UnsupportedScreenWrapper } from '../../ScreenSize';

interface NoticeUnsupportedScreenProps {
    message: string;
}

const NoticeUnsupportedScreen: React.FC<NoticeUnsupportedScreenProps> = ({ message }) => (
    <UnsupportedScreenWrapper>{message}</UnsupportedScreenWrapper>
);

export default NoticeUnsupportedScreen;
