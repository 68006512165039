import { User } from '@frontegg/redux-store/auth/interfaces';

export default function isFronteggUserAdmin(user: User | null) {
    try {
        return user?.roles.some((role) => role.key.toLowerCase() === 'admin');
    } catch (error) {
        console.error(error);
        return false;
    }
}
