import { useQuery } from '@tanstack/react-query';
import { fetchClauses } from '../api/config.api';

export function useClauses() {
    const { data, ...rest } = useQuery({
        queryFn: () => fetchClauses().then((res) => res),
        queryKey: ['clauses'],
        refetchOnWindowFocus: false,
    });

    return {
        clauses: data,
        ...rest,
    };
}
